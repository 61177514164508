import Image from "next/image";
import Link from "next/link";

import { RhIcon } from "@rhythm-ui/react";
import React from "react";

import {
  iconFacebookGradient,
  iconLinkedinGradient,
  iconTwitterGradient,
  zelevateWordLogoWhiteText,
} from "@/constants/media";

import CustomIcon from "../utils/CustomIcon";

const Footer = () => {
  return (
    <>
      <div className="bg-[#1B2434] p-tm2 pb-0 md:px-[40px] md:py-t3xl">
        <div className="grid grid-cols-1 gap-txl lg:grid-cols-4">
          {/* 1/3 */}
          <div className="col-span-1 flex flex-col gap-tm2">
            <Link href="/" passHref>
              <Image
                src={zelevateWordLogoWhiteText}
                alt="logo"
                height="35"
                width="160"
                layout="fixed"
              />
            </Link>
            <div className="ml-tsm flex gap-tm2">
              <a
                target="_blank"
                href="https://in.linkedin.com/company/zelevatehq"
                rel="noreferrer"
              >
                <CustomIcon icon={iconLinkedinGradient} />
              </a>

              <a
                target="_blank"
                href="https://twitter.com/ZelevateHQ"
                rel="noreferrer"
              >
                <CustomIcon icon={iconTwitterGradient} />
              </a>

              <a
                target="_blank"
                href="https://www.facebook.com/zelevateHQ"
                rel="noreferrer"
              >
                <CustomIcon icon={iconFacebookGradient} />
              </a>

              {/* <a
                target="_blank"
                href="https://www.instagram.com/zelevatehq/"
                rel="noreferrer"
              >
                <CustomIcon icon={iconInstagramGadient} />
              </a> */}
            </div>
          </div>
          {/* 2/3 */}
          <div className="col-span-2 grid grid-cols-3 gap-tm2">
            <div className="flex flex-col gap-tm2">
              <h3 className="text-white">For Job Seeker</h3>
              <Link href={"/"} passHref>
                <a className="text-white">Home</a>
              </Link>

              <Link href={"/jobs"} passHref>
                <a className="text-white">Jobs</a>
              </Link>

              <Link href={"/referral"} passHref>
                <a className="text-white">Refer & Earn</a>
              </Link>
            </div>

            <div className="flex flex-col gap-tm2">
              <h3 className="text-white">For Employer</h3>
              <Link href={"/"} passHref>
                <a className="text-white">Home</a>
              </Link>
              {/* 
              <Link href={"/product"} passHref>
                <a className="text-white">Product</a>
              </Link>

              <Link href={"/pricing"} passHref>
                <a className="text-white">Pricing</a>
              </Link> */}
            </div>

            <div className="flex flex-col gap-tm2">
              <h3 className="text-white">For Interviewer</h3>
              <Link href={"/interviewer/apply"} passHref>
                <a className="text-white">Apply</a>
              </Link>
            </div>
          </div>
          {/* 3/3 */}
          <div className="col-span-1 flex w-full flex-col gap-tm2 md:mt-0">
            <h3 className="text-white">Get In Touch</h3>
            <a
              href="mailto:info@zelevate.io"
              className="flex items-center gap-tsm text-white"
            >
              <RhIcon
                icon="material-symbols:mail-outline-sharp"
                className="text-[#179F93]"
              ></RhIcon>{" "}
              info@zelevate.io
            </a>
            {/* <a
              href="tel:+919876543210"
              className="flex items-center text-white gap-tsm"
            >
              <RhIcon
                icon="material-symbols:phone-in-talk"
                className="text-[#179F93]"
              ></RhIcon>{" "}
              +91 9876543210
            </a> */}

            <p className="flex items-center gap-tsm pb-txl text-white md:pb-0">
              Hustlehub Tech Park, #36/5, Somasandrapalya, Sector 2, adjacent
              27th Main Road, HSR Layout, Bengaluru, Karnataka 560102
            </p>
          </div>
        </div>
      </div>

      <div className="flex h-20 flex-row items-center justify-between gap-tm2 border border-b-0 border-l-0 border-r-0 border-[#434343] bg-[#1B2434] px-tm2 py-txl md:px-20 md:py-0">
        <p className="text-xs text-[#C4C4C4]">
          ©2023 Zelevate. All rights reserved.
        </p>
        <div className="flex gap-tsm">
          {/* <span className="text-[#C4C4C4] text-xs"> Privacy Policy</span>
          <span className="text-[#C4C4C4] text-xs"> Terms Of Use</span> */}
        </div>
      </div>
    </>
  );
};

export default Footer;
