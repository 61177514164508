import {
  CarouselNext,
  CarouselPrev,
  RhCarousel,
  RhCarouselItem,
  RhIcon,
} from "@rhythm-ui/react";
import React, { useEffect, useRef } from "react";

import { vectorQuotation } from "@/constants/media";

const Testimonials = ({
  contents,
}: {
  contents: {
    image: string;
    employerLogo: string;
    description: string;
    personName: string;
    personTitle: string;
  }[];
}) => {
  const nextCarouselButton = useRef<null | HTMLDivElement>(null);
  const carouselWindow = useRef<null | HTMLDivElement>(null);

  const intervalId = useRef<any>(null);
  useEffect(() => {
    intervalId.current = setInterval(() => {
      nextCarouselButton.current?.click();
    }, 2000);

    const onMouseEnterHandler = () => {
      if (intervalId.current) clearInterval(intervalId.current);
    };

    const onMouseLeaveHandler = () => {
      if (intervalId.current) clearInterval(intervalId.current);
      intervalId.current = setInterval(() => {
        nextCarouselButton.current?.click();
      }, 7000);
    };

    if (carouselWindow.current) {
      carouselWindow.current.addEventListener(
        "mouseenter",
        onMouseEnterHandler
      );
      carouselWindow.current.addEventListener(
        "mouseleave",
        onMouseLeaveHandler
      );
    }
    return () => {
      if (intervalId.current) clearInterval(intervalId.current);
      if (carouselWindow.current) {
        carouselWindow.current.removeEventListener(
          "mouseenter",
          onMouseEnterHandler
        );
        carouselWindow.current.removeEventListener(
          "mouseleave",
          onMouseLeaveHandler
        );
      }
    };
  }, []);
  return (
    <div className="p-tsm pt-10 lg:p-20">
      <h1 className=" text-center mb-ts">
        Get <span className="text-gradient-1">Inspired</span> By Our Community
        Stories
      </h1>
      {/* <div className="h-[40px]" /> */}
      <div className="flex gap-tsm">
        <div className="w-[90vw] mx-auto mb-12 lg:block" ref={carouselWindow}>
          <RhCarousel
            slidesPerView={1}
            spaceBetween={1}
            slidesPerGroup={1}
            loop
          >
            {contents.map((content) => (
              <RhCarouselItem key={content.image}>
                <div className="flex flex-col md:flex-row gap-tm2 items-center mt-[45px]">
                  <img
                    className="object-cover w-[250px] h-[250px] p-0 m-0 rounded-md"
                    src={content.image}
                  />

                  <div className="flex flex-col gap-tm2 md:px-10 relative ">
                    <p className="flex flex-1 text-base ">
                      {content.description}
                    </p>
                    <div className="flex flex-row gap-tsm md:gap-tm3 ">
                      <div>
                        <h3>{content.personName}</h3>
                        <p className="text-light">{content.personTitle}</p>
                      </div>
                      <div className="border-l-2 h-full flex border-secondary-300  "></div>

                      <img
                        src={content.employerLogo}
                        className="max-w-[130px] md:max-w-[190px] md:w-auto"
                      ></img>
                    </div>

                    <img
                      src={vectorQuotation}
                      className="absolute -top-[75px] right-[40px] w-[90px] h-[174px] md:w-[130px] z-50"
                    />
                  </div>
                </div>
              </RhCarouselItem>
            ))}

            <div className="flex justify-end gap-tsm">
              <CarouselPrev>
                <div className="flex justify-center items-center border border-primary-400 p-tnano rounded-sm">
                  <RhIcon
                    className="text-sm"
                    icon="material-symbols:chevron-left-rounded"
                  ></RhIcon>
                </div>
              </CarouselPrev>
              <CarouselNext>
                <div
                  ref={nextCarouselButton}
                  className="flex justify-center items-center border border-primary-400 p-tnano rounded-sm"
                >
                  <RhIcon
                    className="text-sm"
                    icon="material-symbols:chevron-right"
                  ></RhIcon>
                </div>
              </CarouselNext>
            </div>
          </RhCarousel>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
