// Logo Variation
export const zelevateWordLogoWhiteText =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677242014/zelevate/zelevateLogo/ekiucxulubtm6buck68v.svg";
export const zelevateWordLogoBlackText =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677242583/zelevate/zelevateLogo/enmc7gb1dash8qcwfimy.svg";
export const zelevateLogo =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677242642/zelevate/zelevateLogo/scm3wc0x42879d0klsgp.svg";
export const zelevateLogoShaded =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677242695/zelevate/zelevateLogo/ukpysgqulvferrg4dogu.svg";
export const zelevateLogoGridLook =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677242833/zelevate/zelevateLogo/ry321lhaaf06vpi8rga2.svg";

// Animation
export const animationStartHereArrow =
  "https://res.cloudinary.com/cdmnk/raw/upload/v1677574737/zelevate/landingPageAssests/wdjhorfdqbzcvcmn79fp.json";
// "https://res.cloudinary.com/cdmnk/raw/upload/v1677302938/zelevate/landingPageAssests/yq7wsywganqfkbo1omzb.json";

// vectors
export const vectorSquares =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677311985/zelevate/landingPageAssests/ksadcr023hxgplbsbktg.svg";
export const vectorOfZelevate =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677612672/zelevate/landingPageAssests/fun67g86k9j158d1fd9v.svg";
export const vectorQuotation =
  "https://res.cloudinary.com/cdmnk/image/upload/v1678256327/zelevate/landingPageAssests/bdbw0lmxcc7ddkic5wct.svg";

// common landing page assests
export const zelevateThemeBackgroundBanner =
  "https://res.cloudinary.com/cdmnk/image/upload/v1685967070/zelevate/landingPageAssests/nkcbgl3fsy89sdyoklwp.png";

// Employer landing page
export const employerCandidateCard =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677739579/zelevate/landingPageAssests/qwbd2vviublwr0mqo4dw.svg";

//
export const employerScrollOne =
  "https://res.cloudinary.com/cdmnk/image/upload/v1709712232/zelevate/landingPageAssests/gdo89zd0njeblm1wq1ed.svg";
export const employerScrollTwo =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677303315/zelevate/landingPageAssests/nm7gxqoghmvwn7mvbk0s.png";
export const employerScrollThree =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677303472/zelevate/landingPageAssests/adnye6svva9ejfc2p4jn.png";

export const employeranimationScrollTwo =
  "https://res.cloudinary.com/cdmnk/image/upload/v1708600019/zelevate/landingPageAssests/cnfph0xd09wfhlwlx8qu.png";
export const employeranimationScrollThree =
  "https://res.cloudinary.com/cdmnk/image/upload/v1709712799/zelevate/landingPageAssests/orpgtlzh47mf3drdtzvm.svg";

export const employeranimationScrollFour =
  "https://res.cloudinary.com/cdmnk/image/upload/v1708600337/zelevate/landingPageAssests/zs09w6i3hv8yxwoht9c5.svg ";

export const employerTestimonialOne =
  "https://res.cloudinary.com/cdmnk/image/upload/v1678268553/zelevate/landingPageAssests/eqyrk6hjl7tdeouzgk1h.png";
export const employerTestimonialTwo =
  "https://res.cloudinary.com/cdmnk/image/upload/v1678373313/zelevate/landingPageAssests/b5urd3vqif0n5ykzzntx.png";

// candidate landing page
export const candidateTestimonialOne =
  "https://res.cloudinary.com/cdmnk/image/upload/v1678206044/zelevate/landingPageAssests/qjozuipbjaxs5xoxzwj0.png";
export const candidateTestimonialTwo =
  "https://res.cloudinary.com/cdmnk/image/upload/v1678260614/zelevate/landingPageAssests/syrxvsiwijxrgd71blnc.png";
export const candidateTestimonialThree =
  "https://res.cloudinary.com/cdmnk/image/upload/v1678260618/zelevate/landingPageAssests/ywdtaywoxbzcwvpqrpcv.png";
export const candidateTestimonialFour =
  "https://res.cloudinary.com/cdmnk/image/upload/v1678260700/zelevate/landingPageAssests/b49icub9p88wmpb1xejr.png";

export const candidateScollOne =
  "https://res.cloudinary.com/cdmnk/image/upload/v1686731443/zelevate/landingPageAssests/mrm9qei935nkhtne4alh.svg";
export const candidateScollTwo =
  "https://res.cloudinary.com/cdmnk/image/upload/v1686731593/zelevate/landingPageAssests/giurvs6vdlcxeajhgjmo.svg";
export const candidateScollThree =
  "https://res.cloudinary.com/cdmnk/image/upload/v1686731222/zelevate/landingPageAssests/trmgraymqb0nwriawljl.svg";

// client company logo
export const clientLogoLightCodemonk =
  "https://res.cloudinary.com/cdmnk/image/upload/v1678452182/zelevate/clientLogo/k7ildr3adcnn7k7lesta.svg";
export const clientLogoDarkCodemonk =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677827970/zelevate/clientLogo/phigzwnguwqf4c8dzea6.svg";

export const clientLogoLightZensar =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677470717/zelevate/clientLogo/ttvdh75ibsqigv3glkwf.svg";
export const clientLogoDarkZensar =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677827594/zelevate/clientLogo/rrpuu0fkdct5kqr99squ.svg";

export const clientLogoLight75F =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677471970/zelevate/clientLogo/xpur90xrcolgwikkyty1.svg";
export const clientLogoDark75F =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677827908/zelevate/clientLogo/ypaiyfpyegdimzlqqwww.svg";

export const clientLogoLightOakum =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677475143/zelevate/clientLogo/mkb65scl2qo4blwvrzco.svg";
export const clientLogoDarkOakum =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677827947/zelevate/clientLogo/fkdsct1trx4eylunhh4d.svg";

export const clientLogoLightExperian =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677475201/zelevate/clientLogo/dosfk2cxtcpiwp85hpfq.svg";
export const clientLogoDarkExperian =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677827927/zelevate/clientLogo/llg6evhv4qtknpytjn94.svg";

export const clientLogoLightBitClass =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677472431/zelevate/clientLogo/kmotgpnovf0roirswrch.svg";
export const clientLogoDarkBitClass =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677827817/zelevate/clientLogo/noo7d7ta0k0haimvt5xo.svg";

export const clientLogoEnrichAI =
  "https://res.cloudinary.com/cdmnk/image/upload/v1678373660/zelevate/clientLogo/akx1j4suyo1hprbdkzxi.svg";

export const clientLogoLightAppSmith =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677475241/zelevate/clientLogo/g01urvkoqja3u8ei5lpo.svg";
export const clientLogoDarkAppSmith =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677827885/zelevate/clientLogo/nfefluqcnquan1eqcvvz.svg";

export const clientLogoLightLicious =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677475271/zelevate/clientLogo/nnfqjwmlqwpbkgvnv6ld.svg";
export const clientLogoDarkLicious =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677827988/zelevate/clientLogo/j5akj5lvavmtoiwd84yq.svg";

export const clientLogoDarkGrappus =
  "https://res.cloudinary.com/cdmnk/image/upload/v1678261190/zelevate/clientLogo/pofswue3hlbvy1cxygbm.svg";

export const clientLogoLightCognitiveClouds =
  "https://res.cloudinary.com/cdmnk/image/upload/v1678261194/zelevate/clientLogo/nwarvcdd2t0m6joniyrf.svg";

export const clientLogoDarkCodeCraft =
  "https://res.cloudinary.com/cdmnk/image/upload/v1678261196/zelevate/clientLogo/sosvovzqu6vweumqfo6w.svg";

// own
export const clientLogoLightFisdom =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677766630/zelevate/clientLogo/l47m63nzdqgjfbtf3e7g.jpg";

export const clientLogoCollection =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677475892/zelevate/clientLogo/qmgz7rhhod7c0xooxwnt.svg";

export const clientLogoOnWhiteBG =
  "https://res.cloudinary.com/cdmnk/image/upload/v1678162028/zelevate/clientLogo/hdkihx0qjgow8v58eaaj.svg";
// icons (svg) - landing page
export const iconTwoPesonOutlined =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677747512/zelevate/svg/ewdwnk5umabtxrjx5e7n.svg";
export const iconDocumentOutlined =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677748012/zelevate/svg/bw4eqcqqcps40qdqd9ns.svg";
export const iconBriefcase =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677748281/zelevate/svg/bljb07eabavalr3znfku.svg";

export const iconLinkedinGradient =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677833905/zelevate/svg/i2npqtq1i6ixd3ybcaok.svg";
export const iconInstagramGadient =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677834002/zelevate/svg/tfeuacdp1vdqmg8bonlx.svg";
export const iconFacebookGradient =
  "https://res.cloudinary.com/cdmnk/image/upload/v1677834017/zelevate/svg/g1ztagzx2hpazbxyqxix.svg";
export const iconTwitterGradient =
  "https://res.cloudinary.com/cdmnk/image/upload/v1678272465/zelevate/svg/ksbqvvjb1pzu5wtlxgbn.svg";

// icons for products
export const ICONS = {
  MATCH_JOB:
    "https://res.cloudinary.com/cdmnk/image/upload/v1711096643/zelevate/icons/zbq1gomciumycropym2y.svg",
  UNMATCH_JOB:
    "https://res.cloudinary.com/cdmnk/image/upload/v1711096783/zelevate/icons/mlkhleicyzbn84wl8pt4.svg",
  CHECK:
    "https://res.cloudinary.com/cdmnk/image/upload/v1717577919/zelevate/icons/enquaqso0gxdjv0y79pm.svg",
};
// for downloading reports png files

export const zelevateLogoWhitePNG =
  "https://res.cloudinary.com/cdmnk/image/upload/v1680593612/zelevate/Report%20Assests/pvliflcydidk61og7yji.png";
export const codemonkBlackPNG =
  "https://res.cloudinary.com/cdmnk/image/upload/v1680593674/zelevate/Report%20Assests/eiqvyhmwqvrzhk1i0dpx.png";
export const zelevatePatternPNG =
  "https://res.cloudinary.com/cdmnk/image/upload/v1680593718/zelevate/Report%20Assests/fore6ji8m97mchom7cbj.png";
export const codemonkPatternPNG =
  "https://res.cloudinary.com/cdmnk/image/upload/v1680593776/zelevate/Report%20Assests/yxwk8jbp7ll70tfjwvza.png";

// other svgs
export const mobileNotSupported = `https://res.cloudinary.com/cdmnk/image/upload/v1701252629/zelevate/svg/ujdrlkghgnff9h3kk0n5.svg`;
