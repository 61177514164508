import { RhCard, RhCarousel, RhCarouselItem, RhImage } from "@rhythm-ui/react";
import React, { useMemo } from "react";

import {
  clientLogoDark75F,
  clientLogoDarkAppSmith,
  clientLogoDarkBitClass,
  clientLogoDarkExperian,
  clientLogoDarkLicious,
  clientLogoDarkZensar,
  clientLogoLightCodemonk,
} from "@/constants/media";

import useWindowSize from "@/hooks/useWindowSize";

const CurrentClientsCarousel = ({
  // backgroundColor,
  headingElement,
}: {
  // backgroundColor: string;
  headingElement: any;
}) => {
  const trustedCompanies = [
    {
      name: "bitClass",
      logo: clientLogoDarkBitClass,
      bg: {
        background:
          "linear-gradient(0deg, rgba(7, 152, 147, 0.2), rgba(7, 152, 147, 0.2)), #FFFFFF",
      },
    },
    {
      name: "licious",
      logo: clientLogoDarkLicious,
      bg: {
        background: "#F2DDEB",
      },
    },
    {
      name: "colorweb",
      logo: clientLogoDark75F,
      bg: {
        background: "#F9DACD",
      },
    },
    {
      name: "codemonk",
      logo: clientLogoLightCodemonk,
      bg: {
        background: "#21262C",
      },
    },
    {
      name: "appsmith",
      logo: clientLogoDarkAppSmith,
      bg: {
        background:
          "linear-gradient(0deg, rgba(255, 109, 45, 0.2), rgba(255, 109, 45, 0.2)), #FFFFFF",
      },
    },
    {
      name: "zensar",
      logo: clientLogoDarkZensar,
      bg: {
        background:
          "linear-gradient(0deg, rgba(1, 1, 1, 0.2), rgba(1, 1, 1, 0.2)), #FFFFFF",
      },
    },
    {
      name: "experian",
      logo: clientLogoDarkExperian,
      bg: {
        background:
          "linear-gradient(0deg, rgba(186, 47, 125, 0.2), rgba(186, 47, 125, 0.2)), #FFFFFF",
      },
    },
  ];

  return (
    <div
      className={`justify-center py-10 lg:py-20 fex items first-letter bg-white relative`}
    >
      {/* <h1 className="text-lg text-center mb-[40px] lg:text-4xl md:text-2xl ">
        {heading}
      </h1> */}
      {headingElement}

      <div className="relative w-full mx-auto">
        <div className="companies-slider">
          <Companies
            trustedCompanies={trustedCompanies}
            // backgroundColor={backgroundColor}
          />
        </div>
      </div>

      <div className="hidden md:flex absolute top-0 bottom-0 left-0 z-[2] ">
        <div className={`bg-white w-[80px]`}></div>
        <div className={`w-[80px] bg-gradient-to-r from-white`}></div>
      </div>

      <div className="hidden md:flex absolute top-0 bottom-0 right-0  z-[2] ">
        <div className={`w-[80px] bg-gradient-to-l from-white`}></div>
        <div className={`bg-white w-[80px]`}></div>
      </div>
    </div>
  );
};

export default CurrentClientsCarousel;

const Companies = ({
  trustedCompanies,
}: // backgroundColor,
{
  trustedCompanies: {
    name: string;
    logo: string;
    bg: {
      background: string;
    };
  }[];
  // backgroundColor: string;
}) => {
  const { width: windowSize } = useWindowSize();

  const numberOfTile = useMemo(() => {
    if (!windowSize) return 3;
    if (windowSize < 360) {
      return 2;
    } else if (windowSize < 500) return 3;
    else if (windowSize < 768) return 4;
    else if (windowSize < 1100) return 3;
    else if (windowSize < 1300) return 4;
    return 5;
  }, [windowSize]);

  return (
    <div>
      <RhCarousel
        slidesPerView={numberOfTile}
        autoplay
        loop
        spaceBetween={1}
        className={`[&>.swiper-initialized]:bg-white`}
      >
        {trustedCompanies.map((data, idx) => {
          return (
            <RhCarouselItem key={idx}>
              <RhCard
                style={data.bg}
                className="flex items-center justify-center w-[100px] h-[90px] md:w-[232px] md:h-[128px] rounded-[8px] shadow-none p-tsm md:p-tm3  rounded-sm border-0"
              >
                <RhImage src={data?.logo} />
              </RhCard>
            </RhCarouselItem>
          );
        })}
      </RhCarousel>
    </div>
  );
};
